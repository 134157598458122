import React, { useEffect } from 'react'
import Header from 'src/components/layout/Header'
import Footer from 'src/components/layout/Footer'
import { detectKeyboardUser } from 'src/helpers/layout'
import { Helmet } from 'react-helmet'
import { I18nextContext } from 'gatsby-plugin-react-i18next'
import SideMenu from 'src/components/layout/side-menu'
import CookiesBanner from 'src/components/ui/CookiesBanner'
import SideSectionsNavigation from 'src/components/ui/side-sections-navigation'
/* eslint-disable-next-line import/no-unresolved */
import { globalHistory } from '@reach/router'
import { ScrollTrigger } from 'gsap/all'

interface IMetaProps {
  name?: string
  content?: string
}
interface ILayoutProps {
  title: string
  metas: IMetaProps[]
  children: JSX.Element | (JSX.Element | JSX.Element[])[]
  isSideNavHidden?: boolean
}

const Layout: React.FC<ILayoutProps> = ({
  title,
  metas,
  children,
  isSideNavHidden,
}) => {
  const lngContext = React.useContext(I18nextContext)
  useEffect(() => {
    detectKeyboardUser()
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    const unsubscriber = globalHistory.listen(({ location, action }) => {
      if (action === 'PUSH') {
        ScrollTrigger.clearScrollMemory()
      }
    })

    return () => {
      unsubscriber()
    }
  }, [])

  return (
    <div className="font-roag font-palanquin whitespace-pre-line">
      <Helmet
        htmlAttributes={{ lang: lngContext.language }}
        titleTemplate="MAMF | %s"
      >
        <meta charSet="utf-8" />
        <title>{title}</title>
        {metas.map((meta) => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}

        {/* Pipedrive tracing */}
        <script async src="/pipedrive.js" />
        <script
          id="Cookiebot"
          src="https://consent.cookiebot.com/uc.js"
          data-cbid="55ad7cf8-686a-4367-ae55-55168372b12f"
          data-blockingmode="auto"
          type="text/javascript"
        ></script>
      </Helmet>
      <Header />
      <SideMenu />
      <div className="relative text-black">
        <SideSectionsNavigation
          className={`hidden ${isSideNavHidden ? '' : 'xl:block'}`}
        />
        <main className="main">{children}</main>
        {/*Test*/}
        {/* <CookiesBanner className="fixed left-10 bottom-10 mr-10" /> */}
      </div>
      <Footer />
    </div>
  )
}

Layout.defaultProps = {
  isSideNavHidden: false,
}

export default Layout
