import React, { FC, useEffect, useState } from 'react'

interface ISideSectionsNavigationProps {
  className?: string
}

const SideSectionsNavigation: FC<ISideSectionsNavigationProps> = ({
  className,
}) => {
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0)
  const [sections, setSections] = useState([])

  const getSections = (): HTMLElement[] =>
    Array.from(document.querySelectorAll('section[data-name], div[data-name]'))

  const getCurrentSectionIndex = () =>
    sections.findIndex((section) => {
      const CURRENT_SECTION_POINT = window.innerHeight / 2
      const rect = section.getBoundingClientRect()

      return (
        rect.top < CURRENT_SECTION_POINT && rect.bottom > CURRENT_SECTION_POINT
      )
    })

  const updateCurrentSection = () => {
    setCurrentSectionIndex(getCurrentSectionIndex())
  }

  const handleItemClick = (section: HTMLElement) => {
    const { top } = section.getBoundingClientRect()
    const { scrollY } = window

    window.scrollTo({
      top: top + scrollY,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    setSections(getSections())

    window.addEventListener('scroll', updateCurrentSection)

    return () => {
      window.removeEventListener('scroll', updateCurrentSection)
    }
  }, [currentSectionIndex])

  const getLabel = (isActive: boolean, section: HTMLElement, index) => {
    if (isActive) {
      return (
        <>
          <span className="text-secondaryGray700 font-medium text-xs">
            {section.dataset.name}
          </span>
          <span
            className="text-red text-xs absolute font-semibold top-1/2 -left-8"
            style={{ transform: 'translateY(-50%)' }}
          >
            {`0${index}`.slice(-2)}
          </span>
        </>
      )
    }
    return (
      <span className="text-secondaryGray500  font-medium text-xs">
        {`0${index}`.slice(-2)}
      </span>
    )
  }

  const getSectionsMappedToNavComponent = () =>
    sections.map((section, index) => {
      const isScrolled = index <= currentSectionIndex
      const isActive = index === currentSectionIndex
      const isLast = index === sections.length - 1

      return (
        <li
          key={section.dataset.name}
          className={`h-16 border-l-2 relative flex items-center transition duration-500 ${
            isScrolled ? 'border-red' : 'border-secondaryGray500'
          }`}
        >
          <button
            type="button"
            style={{ maxWidth: '100px' }}
            onClick={() => handleItemClick(section)}
            className="pl-2 text-secondaryGray700 relative leading-18px text-left"
          >
            {getLabel(isActive, section, index)}
          </button>
          <div
            style={{ left: '-4px', width: '6px', height: '6px' }}
            className={`absolute transition duration-700 bottom-0 
            ${isScrolled ? 'bg-red' : 'bg-secondaryGray500'}
            ${isLast ? 'hidden' : ''}
            rounded-full`}
          />
        </li>
      )
    })

  return (
    <div
      style={{ transform: 'translateY(-50%)' }}
      className={`${className} fixed z-10 left-14 top-1/2`}
    >
      <ul>{getSectionsMappedToNavComponent()}</ul>
    </div>
  )
}

SideSectionsNavigation.defaultProps = {
  className: '',
}

export default SideSectionsNavigation
