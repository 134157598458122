// i18next-extract-mark-ns-start side-menu

import React, { FC, useEffect, useRef, MouseEvent } from 'react'
import CloseButton from 'src/components/layout/side-menu/CloseButton'
import useSideMenuStore from 'src/components/layout/side-menu/store'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import FacebookIcon from 'src/images/svg/facebook.inline.svg'
import LinkedinIcon from 'src/images/svg/linkedin.inline.svg'
import BehanceIcon from 'src/images/svg/behance.inline.svg'
import DribbbleIcon from 'src/images/svg/dribbble.inline.svg'
import styled from 'styled-components'
import tw from 'twin.macro'
import mamfWhiteLogo from 'src/images/svg/mamf-white-logo.svg'

interface ISideMenuProps {
  className?: string
}

const StyledLink = styled(Link)`
  &.active::before {
    ${tw`block absolute w-2 h-2 bg-red -left-3 rounded-sm`}
    content: ' ';
  }
`

const StyledMiniLink = styled(Link)`
  &.active {
    ${tw`text-black`}
  }
`

const SideMenu: FC<ISideMenuProps> = ({ className }) => {
  const { t } = useTranslation('side-menu')
  const { isSideMenuOpen, closeSideMenu } = useSideMenuStore((state) => state)
  const menuRef = useRef<HTMLDivElement>(null)

  const sideMenuStructure = [
    {
      name: t('home'),
      url: '/',
    },
    {
      name: t('sport'),
      url: '/sport',
      partiallyActive: true,
    },
    {
      name: t('services'),
      url: '/services',
      partiallyActive: true,
    },
    {
      name: t('case study'),
      url: '/case-studies',
      partiallyActive: true,
    },
    {
      name: t('career'),
      url: '/career',
      partiallyActive: true,
    },
  ]

  const isHiddenStyles = isSideMenuOpen ? '' : 'translate-x-full'

  const handleMenuLinkClick = (e: MouseEvent) => {
    e.stopPropagation()
    closeSideMenu()
  }

  const handleDocumentClick = (event) => {
    const isClickedOutside =
      menuRef.current && !menuRef.current.contains(event.target)

    if (isClickedOutside) {
      closeSideMenu()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick)

    return () => {
      document.removeEventListener('click', handleDocumentClick)
    }
  }, [isSideMenuOpen])

  return (
    <>
      <div
        className={`${
          isSideMenuOpen ? 'show' : 'hidden'
        } z-20 fixed inset-0 md:py-6 bg-blackTransparent80`}
      >
        <div className="container">
          <img src={mamfWhiteLogo} alt="mamg white logo" />
        </div>
      </div>
      <div
        style={{ width: '590px' }}
        ref={menuRef}
        className={`
          ${className} ${isHiddenStyles}
          transform transition duration-500 max-w-full
          fixed z-30 right-0 top-0 bottom-0 bg-white w-64 p-8 shadow-2xl
          overflow-y-scroll
        `}
      >
        <div className="flex justify-end">
          <CloseButton onClick={handleMenuLinkClick} />
        </div>
        <div className="p-6">
          <ul>
            {sideMenuStructure.map(({ url, name, partiallyActive }) => (
              <li className="mb-4 text-secondaryGray600" key={name}>
                <StyledLink
                  partiallyActive={partiallyActive}
                  onClick={handleMenuLinkClick}
                  className="text-2xl font-palanquinDark font-semibold relative hover:text-black transition"
                  activeClassName="text-black active"
                  to={url}
                >
                  {name}
                </StyledLink>
              </li>
            ))}
          </ul>
        </div>
        <hr />
        <div className="p-6 flex flex-wrap">
          <div className="w-full md:w-1/2 flex flex-col">
            <StyledMiniLink
              partiallyActive
              activeClassName="text-black active"
              onClick={handleMenuLinkClick}
              to="/iso"
              className="mb-4 text-secondaryGray600 hover:text-black transition"
            >
              {t('iso 27001')}
            </StyledMiniLink>
            <StyledMiniLink
              partiallyActive
              activeClassName="text-black active"
              onClick={handleMenuLinkClick}
              to="/investor-relations"
              className="mb-4 text-secondaryGray600 hover:text-black transition"
            >
              {t('investor relations')}
            </StyledMiniLink>
            <StyledMiniLink
              partiallyActive
              activeClassName="text-black active"
              onClick={handleMenuLinkClick}
              to="/privacy-policy"
              className="mb-4 text-secondaryGray600 hover:text-black transition"
            >
              {t('privacy policy / GPDR')}
            </StyledMiniLink>
          </div>
          <div className="w-full md:w-1/2 flex flex-col">
            <StyledMiniLink
              partiallyActive
              activeClassName="text-black active"
              onClick={handleMenuLinkClick}
              to="/security"
              className="mb-4 text-secondaryGray600 hover:text-black transition"
            >
              {t('security')}
            </StyledMiniLink>
            <StyledMiniLink
              partiallyActive
              activeClassName="text-black active"
              onClick={handleMenuLinkClick}
              to="/technology-radar"
              className="mb-4 text-secondaryGray600 hover:text-black transition"
            >
              {t('technology')}
            </StyledMiniLink>
            <StyledMiniLink
              partiallyActive
              activeClassName="text-black active"
              onClick={handleMenuLinkClick}
              to="/contact"
              className="mb-4 text-secondaryGray600 hover:text-black transition"
            >
              {t('contact')}
            </StyledMiniLink>
          </div>
        </div>
        <hr />
        <div className="py-12 flex justify-between">
          <div className="text-secondaryGray600">
            {/* TODO: language switcher */}
          </div>
          <div className="flex items-end">
            <a
              href="https://www.facebook.com/grupamamf"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </a>
            <a
              href="https://www.linkedin.com/company/memyfriends/mycompany/"
              target="_blank"
              rel="noreferrer"
              className="ml-16"
            >
              <LinkedinIcon />
            </a>
            <a
              href="https://www.behance.net/teammamf"
              target="_blank"
              rel="noreferrer"
              className="ml-16"
            >
              <BehanceIcon />
            </a>
            <a
              href="https://dribbble.com/mamfteam"
              target="_blank"
              rel="noreferrer"
              className="ml-16"
            >
              <DribbbleIcon />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

SideMenu.defaultProps = {
  className: '',
}

export default SideMenu
