import React, { Fragment } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import AndIcon from 'src/images/svg/ampersand.svg'
import shim from 'string.prototype.matchall/shim'

shim()

interface IAdjustedImgProps {
  width: number
  marginLeft: number
  marginRight: number
  marginBottom: number
}

const AdjustedImg = styled.img<IAdjustedImgProps>`
  ${tw`inline align-baseline`}
  width: ${(props) => props.width.toFixed(4)}em;
  margin-bottom: ${(props) => props.marginBottom.toFixed(4)}em;
  margin-left: ${(props) => props.marginLeft.toFixed(4)}em;
  margin-right: ${(props) => props.marginRight.toFixed(4)}em;
`

const HORIZONTAL_MARGIN = 0.1

const getMargin = (neighbourCharacter: string): number =>
  neighbourCharacter.trim() === '' ? 0 : HORIZONTAL_MARGIN

const getLeftMargin = (previousToken?: string[]): number =>
  previousToken ? getMargin(previousToken[0].slice(-1)) : 0

const getRightMargin = (nextToken?: string[]): number =>
  nextToken ? getMargin(nextToken[0].charAt(0)) : 0

const getAmpIcon = (
  index: number,
  ampersandScale: number,
  previousToken?: string[],
  nextToken?: string[]
): JSX.Element => (
  <AdjustedImg
    key={`${index}`}
    width={ampersandScale * 0.7}
    marginBottom={0.3 * (1 - ampersandScale) - 0.05}
    marginLeft={getLeftMargin(previousToken)}
    marginRight={getRightMargin(nextToken)}
    src={AndIcon}
    alt="and"
  />
)

const emphasizeAmpersand = (
  text?: string,
  ampersandScale: number = 1
): JSX.Element[] | null => {
  if (!text) {
    return null
  }

  return Array.from(text.matchAll(/&|[^&]+/g)).map(([token], index, arr) =>
    token === '&' ? (
      getAmpIcon(index, ampersandScale, arr[index - 1], arr[index + 1])
    ) : (
      // eslint-disable-next-line react/no-array-index-key
      <Fragment key={`${index}`}>{token}</Fragment>
    )
  )
}

export default emphasizeAmpersand
