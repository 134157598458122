// i18next-extract-mark-ns-start side-menu

import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { FC, MouseEventHandler } from 'react'
import closeSvg from 'src/images/svg/close.svg'

interface ICloseButtonProps {
  className?: string
  onClick?: MouseEventHandler<Element>
}

const CloseButton: FC<ICloseButtonProps> = ({ className, onClick }) => {
  const { t } = useTranslation('side-menu')
  return (
    <button
      type="button"
      style={{ width: '40px', height: '40px' }}
      className={`${className} bg-red flex items-center justify-center w-10 h-10  rounded-md`}
      onClick={onClick}
    >
      <img src={closeSvg} alt={t('Close menu')} />
    </button>
  )
}

CloseButton.defaultProps = {
  className: '',
  onClick: () => {},
}

export default CloseButton
