import React, { FC } from 'react'
import styled from 'styled-components'

const StyledButton = styled.button`
  --smooth-corners: 2.1;
`
export enum ButtonSizes {
  small = 'small',
  medium = 'medium',
  big = 'big',
}

interface ISemiRoundedButtonProps {
  onClick?: () => void
  className?: string
  size?: ButtonSizes
  isOutline?: boolean
  isDisabled?: boolean
  type?: 'button' | 'submit' | 'reset'
}

const SemiRoundedButton: FC<ISemiRoundedButtonProps> = ({
  onClick,
  className,
  children,
  size,
  isOutline,
  isDisabled,
  type,
}) => {
  const buttonMainStyles = `
    inline-block rounded-lg
    transition duration-500 border-2 ${className ? `${className}` : ''}`

  const outlineStyles =
    'text-red bg-white hover:bg-red hover:text-white border-red'
  const filledStyles =
    'text-white bg-red hover:bg-white hover:text-red border-red'
  const disabledStyles =
    'text-white bg-secondaryGray500 border-secondaryGray500 cursor-not-allowed	'

  const getButtonTypeStyles = () => {
    if (isDisabled) {
      return disabledStyles
    }
    if (isOutline) {
      return outlineStyles
    }

    return filledStyles
  }

  const getButtonSizedStyles = () => {
    switch (size) {
      case ButtonSizes.small:
        return 'px-7 pt-1 pb-2'
      case ButtonSizes.medium:
        return 'px-3.5 md:px-7.5 pt-1 md:pt-3 pb-2 md:pb-4 leading-none font-semibold leading-normal'
      default:
        return 'px-8 text-xl'
    }
  }

  const buttonSizedStyles = `${getButtonSizedStyles()}`
  const buttonTypeStyles = `${getButtonTypeStyles()}`

  const buttonStyles = `${buttonMainStyles} ${buttonSizedStyles} ${buttonTypeStyles}`

  const handleClick = () => {
    if (isDisabled) {
      return
    }

    onClick()
  }

  return (
    // https://github.com/yannickcr/eslint-plugin-react/issues/1555#issuecomment-377339656
    /* eslint-disable-next-line react/button-has-type */
    <StyledButton type={type} className={buttonStyles} onClick={handleClick}>
      {children}
    </StyledButton>
  )
}

SemiRoundedButton.defaultProps = {
  size: ButtonSizes.medium,
  onClick: () => {},
  isDisabled: false,
  isOutline: false,
  className: '',
  type: 'button',
}

export default SemiRoundedButton
