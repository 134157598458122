// i18next-extract-mark-ns-start footer

import React from 'react'
import LinkedIcon from 'src/images/svg/linkedin.inline.svg'
import FacebookIcon from 'src/images/svg/facebook.inline.svg'
import BehanceIcon from 'src/images/svg/behance.inline.svg'
import DribbbleIcon from 'src/images/svg/dribbble.inline.svg'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'

const Footer = () => {
  const { t } = useTranslation('footer')
  return (
    <footer className="py-12 md:flex md:justify-between container" id="footer">
      <div className="flex justify-center items-center">
        <a
          href="https://www.facebook.com/grupamamf"
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon />
        </a>
        <a
          href="https://www.linkedin.com/company/memyfriends/mycompany/"
          target="_blank"
          rel="noreferrer"
          className="ml-16"
        >
          <LinkedIcon />
        </a>
        <a
          href="https://www.behance.net/teammamf"
          target="_blank"
          rel="noreferrer"
          className="ml-16"
        >
          <BehanceIcon />
        </a>
        <a
          href="https://dribbble.com/mamfteam"
          target="_blank"
          rel="noreferrer"
          className="ml-16"
        >
          <DribbbleIcon />
        </a>
      </div>
      <div className="text-center mt-8 md:mt-0 md:flex leading-20px md:leading-24px font-semibold text-sm md:text-base">
        <div>
          <Link to="/iso">{t('ISO 27001')}</Link>
        </div>
        <div className="mt-4 md:mt-0 md:ml-16 text-megaDarkGray">
          <Link to="/investor-relations" rel="noreferrer">
            {t('Investor Relations')}
          </Link>
        </div>
        <div className="mt-4 md:mt-0 md:ml-16 text-megaDarkGray">
          <Link to="/privacy-policy" target="_blank">
            {t('Privacy Policy / GDPR')}
          </Link>
        </div>
      </div>
    </footer>
  )
}

export default Footer
