// i18next-extract-mark-ns-start header

import React, { FC, useEffect } from 'react'
import Logo from 'src/images/svg/logo.inline.svg'
import Button from 'src/components/ui/Button'
import HamburgerIcon from 'src/images/svg/HamburgerIcon.inline.svg'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import useSideMenuStore from 'src/components/layout/side-menu/store'

interface IHeaderProps {}

const Header: FC<IHeaderProps> = (): JSX.Element => {
  const openSideMenu = useSideMenuStore((state) => state.openSideMenu)
  const { t } = useTranslation('header')

  const showHeaderWhenScrollingUp = () => {
    let previousScrollPosition = 0

    return () => {
      const header = document.getElementById('header')
      const headerContainer = document.getElementById('header-container')
      const headerHeight = header.offsetHeight
      headerContainer.style.position = 'fixed'
      const currentHeaderOffset = parseInt(headerContainer.style.top, 10) || 0
      const pixelsScrolled = window.scrollY - previousScrollPosition
      const thisScrollOffset = pixelsScrolled * -1
      const newHeaderOffset = currentHeaderOffset + thisScrollOffset
      const newPositon = Math.min(
        Math.max(newHeaderOffset, headerHeight * -1),
        0
      )

      if (window.scrollY < 0) {
        headerContainer.style.top = `${newPositon - window.scrollY}px`
      } else {
        headerContainer.style.top = `${newPositon}px`
      }

      previousScrollPosition = window.scrollY
    }
  }

  useEffect(() => {
    const scrollFunction = showHeaderWhenScrollingUp()

    showHeaderWhenScrollingUp()

    window.addEventListener('scroll', scrollFunction)
    return () => {
      window.removeEventListener('scroll', scrollFunction)
    }
  })

  const handleButtonClick = (e) => {
    e.stopPropagation()
    openSideMenu()
  }

  return (
    <>
      <div
        id="header-container"
        className="bg-white fixed top-0 left-0 right-0 z-20"
      >
        <header id="header" className="md:py-6 flex justify-between container">
          <div>
            <Link to="/">
              <Logo className="w-28 md:w-40" />
            </Link>
          </div>
          <div className="flex items-center">
            <Link to="/contact">
              <Button className="mr-6 hidden md:block" isOutline size="small">
                {t('contact us')}
              </Button>
            </Link>
            <button type="button" onClick={handleButtonClick}>
              <HamburgerIcon />
            </button>
          </div>
        </header>
      </div>
    </>
  )
}

export default Header
