import create from 'zustand'

interface ISideMenuState {
  isSideMenuOpen: boolean
  closeSideMenu: () => void
  openSideMenu: () => void
}

const useSideMenuStore = create<ISideMenuState>((set) => ({
  isSideMenuOpen: false,
  closeSideMenu: () => set(() => ({ isSideMenuOpen: false })),
  openSideMenu: () => set(() => ({ isSideMenuOpen: true })),
}))

export default useSideMenuStore
