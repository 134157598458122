import { useEffect, useState } from 'react'

const IS_MOBILE_FIRST = true

export enum Breakpoints {
  sm = 640,
  md = 768,
  lg = 1024,
  xl = 1280,
  '2xl' = 1536,
}

export const getArrayOfBreakpoints = () =>
  Object.keys(Breakpoints)
    .filter((e) => !Number.isNaN(Number(e)))
    .map((e) => Number(e))

export const isBrowser = typeof window !== 'undefined'

export const isMinWidth = (breakpoint: Breakpoints): boolean =>
  isBrowser ? window.innerWidth >= breakpoint : !IS_MOBILE_FIRST

export const useIsMinWidth = (breakpoint: Breakpoints): boolean => {
  const [isMin, setIsMin] = useState(null)
  const updateValue = () => setIsMin(isMinWidth(breakpoint))

  useEffect(() => {
    updateValue()
    window.addEventListener('resize', updateValue)
    return () => window.removeEventListener('resize', updateValue)
  })

  return isMin
}
export const isSmScreen = (): boolean => isMinWidth(Breakpoints.sm)

export const isMdScreen = (): boolean => isMinWidth(Breakpoints.md)

export const isLargeScreen = (): boolean => isMinWidth(Breakpoints.lg)

export const isXlScreen = (): boolean => isMinWidth(Breakpoints.xl)

export const is2xlScreen = (): boolean => isMinWidth(Breakpoints['2xl'])

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export const useIsLargeScreen = () => useIsMinWidth(Breakpoints.lg)
export const useIsMdScreen = () => useIsMinWidth(Breakpoints.md)

export const detectKeyboardUser = () => {
  document.body.addEventListener('mousedown', () => {
    document.body.classList.add('using-mouse')
  })

  document.body.addEventListener('keydown', (event) => {
    if (event.keyCode === 9) {
      document.body.classList.remove('using-mouse')
    }
  })

  document.body.addEventListener('keydown', () => {
    document.body.classList.remove('using-mouse')
  })
}
